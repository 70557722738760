<template>
  <div
    class="px-container sm:px-container-sm"
    :class="isMax ? 'xl:px-container-max' : 'xl:px-container-xl'"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    isMax: {
      type: Boolean,
      required: false,
    },
  },
}
</script>
